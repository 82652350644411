<template>
    <SectionLayout>
        <v-col cols="12" class="ma-0 pa-0">
        <RequireLogin>
            <v-snackbar v-model="snackbarAccountDeleteFailed" :timeout="2000" top color="red" class="mt-0 pt-0">
                <span>Failed to delete account</span>
                <v-btn text class="red white--text" @click="snackbarAccountDeleteFailed = false"><font-awesome-icon icon="check" fixed-width/></v-btn>
            </v-snackbar>
            <v-row justify="center" class="pt-5">
                <h1 class="display-1 font-weight-light">Account</h1>
            </v-row>
            <v-row justify="center" class="pb-5">
                <p class="caption font-weight-light">{{email}}</p>
            </v-row>
            <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card tile elevation="4" class="px-10 pb-5" style="border: solid 1px red;">
                        <v-card-text class="title red--text text-center">Delete Account</v-card-text>
                        <v-divider class="mx-5 mb-10"></v-divider>
                        <v-row justify="center">
                            <p class="body-1 font-weight-light pb-2">All account data will be permanently deleted.</p>
                            <p class="body-1 font-weight-light pb-2">NOTE: you must cancel all subscriptions before you can delete the account</p>
                        </v-row>
                        <v-dialog v-model="dialogDeleteAccount" max-width="600">
                            <template v-slot:activator="{ on }">
                                <v-row justify="center" class="pb-2">
                                    <v-btn tile outlined elevation="4" color="red white--text" v-on="on">Delete Account</v-btn>
                                </v-row>
                            </template>
                            <v-card tile elevation="4" style="border: solid 1px red;" max-width="600" class="py-2 px-4">
                                <v-card-title>
                                    <v-row justify="center">
                                        <span class="headline red--text">Delete Account</span>
                                        <span class="pr-5" style="position: absolute; right: 0;">
                                            <v-btn icon color="grey" href="#" @click="dialogDeleteAccount = false">
                                                <font-awesome-icon icon="times" fixed-width style="font-size: 16px;"/>
                                            </v-btn>
                                        </span>
                                    </v-row>
                                </v-card-title>
                                <v-divider class="mx-5"></v-divider>
                                <v-card-text>
                                <v-row justify="center">
                                    <v-col cols="12" class="pa-0">
                                        <p class="body-1 font-weight-light text-center">All account data will be permanently deleted.</p>
                                        <p class="body-1 font-weight-light text-center" v-show="isVpnEnabled">VPN access will be suspended immediately without refund. If you want to use the VPN service through the end of the current billing period, you should cancel the VPN service renewal now, and wait until after the end of the billing period to delete your account.</p>
                                    </v-col>
                                </v-row>
                                </v-card-text>
                                <v-card-actions>
                                    <v-row justify="center">
                                        <v-btn color="red white--text" tile elevation="4" @click="deleteAccount">Delete</v-btn>
                                    </v-row>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-card>
                </v-col>
            </v-row>
        </RequireLogin>
        </v-col>
    </SectionLayout>
</template>

<style scoped>
p {
    color: #555 !important;
}
p a  {
    color: #00f !important;
}
p a:visited  {
    color: #00f !important;
}
p a:hover  {
    color: #00f !important;
    text-decoration: underline;
}
</style>

<script>
import { mapState /* , mapGetters */ } from 'vuex';
import SectionLayout from '@/components/SectionLayout.vue';
import RequireLogin from '@/components/RequireLogin.vue';
import { Client } from '@/client';

const client = new Client();

export default {
    components: {
        RequireLogin,
        SectionLayout,
    },
    data() {
        return {
            name: null,
            alias: null,
            email: null,
            subscriptionList: [],
            orderList: [],
            // vpnConfig: null,
            dialogDeleteAccount: false,
            snackbarAccountDeleteFailed: false,
        };
    },
    computed: {
        ...mapState({
            isReady: (state) => state.isReady,
            user: (state) => state.user,
            session: (state) => state.session,
            // name: (state) => state.user.name,
            // alias: (state) => state.user.alias,
        }),
    },
    watch: {
        isReady(value, oldValue) {
            if (value && !oldValue) {
                this.init();
            }
        },
        dialogDeleteAccount(val) {
            if (!val) {
                this.closeDialogDeleteAccount();
            }
        },
    },
    methods: {
        async init() {
            console.log(`Account.vue: init user ${JSON.stringify(this.user)}`);
            this.name = this.user.name;
            this.alias = this.user.alias;
            this.email = this.user.email;
            /*
            const response = await client.account.get();
            console.log(`account: ${JSON.stringify(response)}`);
            this.name = response.name;
            if (response.productList) {
                for (let i = 0; i < response.productList.length; i += 1) {
                    const product = response.productList[i];
                    if (product.tag === 'vpn') {
                        this.isVpnEnabled = true;
                    }
                }
            }
            */
        },
        async deleteAccount() {
            this.$store.commit('loading', { deleteAccount: true });
            const response = await client.user.delete();
            if (response.isDeleted) {
                await this.$store.dispatch('logout');
                this.$router.push('/');
                console.log('account.vue: deleteAccount success: %o', response);
            } else {
                console.log('account.vue: deleteAccount failed: %o', response);
                this.snackbarAccountDeleteFailed = true;
            }
            this.closeDialogDeleteAccount();
            this.$store.commit('loading', { deleteAccount: false });
        },
        closeDialogDeleteAccount() {
            this.dialogDeleteAccount = false;
        },
    },
    mounted() {
        if (this.isReady) {
            this.init();
        }
    },
};
</script>
